import React from "react";
import { useNavigate } from "react-router-dom";

import "./auths.scss";
import "../modal.scss";

const PrivacyPolicyContent = ({ slider, page }) => {
  const navigate = useNavigate();

  return (
    <div className="row auths-main-row mx-0">
      <div
        className="col-12 col-sm-12 col-md-11 col-xl-11 mx-auto auths-details"
        style={{ marginTop: slider ? 0 : "-3rem" }}
      >
        {page && (
          <div className="back-button" onClick={() => navigate("/")}>
            <img src="/assets/icons/left-arrow.svg" alt="back" />
          </div>
        )}
        <div
          className={slider ? "row tc-conditions mt-3" : "row tc-conditions"}
        >
          <div className="col-12 text-center">
            <h5>LAST MINUTE APP - PRIVACY POLICY</h5>
          </div>
        </div>
        <div
          className={`col-12 tc-conditions-inner ${page ? "full-height" : ""}`}
        >
          <div className="col-12">
            <p>
              <span className="pcHeader">
                LAST MINUTE APP LIMITED ("us", "we", or "our")
              </span>{" "}
              takes your privacy seriously. This page describes our policies and
              practices concerning the collection, use, and disclosure of
              Personal Information (the <b>“Privacy Policy”</b>). Unless
              otherwise specified, all capitalized terms in this Privacy Policy
              have the same meaning as in our Terms of Service.
            </p>
            <p>
              This Privacy Policy applies to all users of our Platform. By using
              the Platform, or by creating an Account you signify your consent
              to the terms of this Privacy Policy. If you do not agree with any
              terms of this Privacy Policy, please do not use the Platform or
              submit any personal information to us.
            </p>
            <h6>A. What Personal Information Do We Collect? </h6>
            <p>
              The application of this Privacy Policy is limited to Personal
              Information and does not apply to business information to the
              extent it is not also Personal Information. Canadian privacy
              legislation defines <b>“Personal Information”</b> broadly as
              information about an identifiable individual or as information
              that allows an individual to be identified.
            </p>
            <p>
              We collect Personal Information from You when you voluntarily
              provide it through our Platform, including by creating an Account,
              otherwise provide it through functionality provided by the
              Platform or when you provide it to us through other means such as
              by contacting us by email or phone. We may also collect Personal
              Information about you from other sources such as third-party
              service providers, publicly available sources and other users of
              the Platform.
            </p>
            <p>
              The types of Personal Information that we may collect about you
              include:{" "}
            </p>
            <ul>
              <li>
                Contact information, such as your full name, home or business
                address, personal or business telephone number and personal or
                business email address;
              </li>
              <li>
                Your user name, password and other information provided on
                creating an Account;
              </li>
              <li>
                Demographic information such as your age, birth date and gender;
              </li>
              <li>
                Your current location and geulcation data from your mobile phone
                or other device;
              </li>
              <li>Payment and billing information;</li>
              <li>
                Information regarding the Third-Party Services you require and
                details of appointments for Third-Party Services;
              </li>
              <li>
                Any Personal Information contained in any materials submitted to
                the Platform, including in any listings uploaded by any
                Third-Party Businesses;
              </li>
            </ul>

            <h6>B. How do we use your Personal Information? </h6>
            <p>
              The Personal Information we collect from you may be used by us for
              the purposes for which it was collected, as provided in this
              Privacy Policy, or for other purposes that are disclosed to You
              and to which You consent. Without limiting the generality of the
              foregoing, we may use, combine, analyze, process, and store
              information we collect from You for the following purposes:
            </p>
            <p>
              <ul>
                <li>To facilitate Account registration;</li>
                <li>
                  To facilitate use of the Platform for its intended purpose,
                  including facilitating appointments between Clients and
                  Third-Party Businesses. Please note that for this purpose, we
                  may share certain Client information with the relevant
                  Third-Party Business with which they have booked an
                  appointment, as further set out in section C of this Privacy
                  Policy;
                </li>
                <li>
                  To verify your identity and protect against error and fraud,
                  including verifying your identity or authenticating your
                  payment information when making a payment through the
                  Platform;
                </li>
                <li>To respond to inquiries and provide customer support; </li>
                <li>
                  To combine with information obtained from third parties to
                  better understand your needs and preferences;
                </li>
                <li>
                  To identify your needs and preferences and recommend other
                  Third-Party Businesses You may like;
                </li>
                <li>
                  To gather analysis or valuable information so that we can
                  improve our Platform;
                </li>
                <li>To verify compliance with our Terms;</li>
                <li>
                  To monitor the usage of our Platform as well as detect,
                  prevent and address technical and security issues with the
                  Platform;
                </li>
                <li>
                  For any other purposes that are disclosed to you and to which
                  you consent;
                </li>
                <li>For any purposes required or authorized by law.</li>
              </ul>
              <p>
                Some or all the information we collect may be stored or
                processed in jurisdictions outside of Canada. As a result, this
                information may be subject to access requests from governments,
                courts, or law enforcement in those jurisdictions according to
                laws in those jurisdictions.
              </p>
            </p>
            <h6>C. Sharing Information With Third Parties</h6>
            <p>
              Under certain circumstances, for the purposes set out above, we
              may disclose your Personal Information to third parties, including
              as follows:
            </p>
            <ul>
              <li>
                to Third-Party Businesses for the purposes of facilitating
                appointments for Third-Party Services. The Personal Information
                we may share with Third-Party Businesses includes:
              </li>
              <li>
                we may provide your payment and related information to third
                party service providers, such as Stripe. Certain payment
                information may also be provided to such payment providers
                directly through functionality provided by the Platform;
              </li>
              <li>
                if required to do so by law or in response to valid requests by
                public authorities (e.g. a court or a government agency);
              </li>
              <li>
                based on our good faith belief that such action is necessary to
                protect and defend our rights or property, to protect the
                personal safety of our customers or the public or to protect
                against legal liability;
              </li>
              <li>
                to a third party in the event that we merge with or are acquired
                by a third party, or as part of the negotiations leading up to
                such merger or acquisition;
              </li>
              <li>
                for any other purpose permitted by law or to which you consent.
              </li>
            </ul>
            <p>
              Please be aware that any Personal Information disclosed to any
              third parties as set out above, including any Personal Information
              disclosed to any Third-Party Business or other service providers
              will be subject to the privacy policies and terms of service of
              such third parties. We have no control over the privacy policies
              or privacy practices of such third parties, including any
              Third-Party Businesses.
            </p>
            <h6>D. Cookies and Usage Data </h6>
            <p>
              In addition to any information that you choose to submit to us, we
              may use a variety of technologies that store or access information
              already stored on your personal computer, laptop, tablet or mobile
              phone (each a device) whenever you visit or interact with our
              website.
            </p>
            <p>
              We use Cookies to collect information about you and your use of
              our Platform. Cookies are files with small amounts of data which
              may include an anonymous unique identifier. Cookies are sent to
              your browser from a website and stored on your device. Tracking
              technologies may also be used, such as beacons, tags, and scripts,
              to collect and track information and to improve functionality and
              performance of our website, including tailoring our website to
              increase the relevancy of content displayed to you.
            </p>
            <p>
              When you visit our Platform, we may use any of the above
              technologies to collect information such as the type of Internet
              browser and operating system you use, the domain name of our
              website from which you came, your preferences, date and duration
              of the visit, number of visits, average time spent on our website,
              pages viewed and number of cookies accumulated. We use cookies to
              improve and customize your browsing experience and to analyze and
              measure information about visitors’ on our website and on other
              platforms, and for the other purposes identified above.
            </p>
            <p>
              We may also monitor traffic patterns, usage, and related
              information to optimize your visit to our Platform. This data may
              include information such as your computer's Internet Protocol
              address (e.g. IP address), browser type, browser version, the
              pages of our website that you visit, the time and date of your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data, and the location from which you are
              accessing our Services.
            </p>
            <p>
              We obtain your consent to our information collection technologies
              by providing you with transparent information in our Privacy
              Policy and providing you with the opportunity to make your choice
              whether to use our website. You have the right to object to the
              use of information collection technologies. Regular cookies may
              generally be disabled or removed by tools that are available as
              part of most commercial browsers, and in some but not all
              instances can be blocked in the future by selecting certain
              settings. Each browser you use will need to be set separately and
              different browsers offer different functionality and options in
              this regard.{" "}
            </p>
            <p>
              Please be aware that if you disable or remove these technologies
              some parts of our Platform may not function effectively. When you
              revisit our website your ability to limit cookies is subject to
              your browser settings and limitations.{" "}
            </p>
            <h6>E. Withdrawal of Consent</h6>
            <p>
              You may withdraw your consent to our collection, use, and
              disclosure of your Personal Information at any time, subject to
              legal and/or contractual restrictions and reasonable notice. Your
              withdrawal of consent to our collection, use and disclosure of
              your Personal Information may impact your ability to use our
              Platform. To withdraw consent, please contact our Privacy Officer
              at [NTD – please insert privacy officer contact email] or by using
              the contact information provided below in our Contact Information
              section.
            </p>
            <h6>F. Safeguards and Risks</h6>
            <p>
              We appropriate safeguards to ensure that your Personal Information
              is protected against loss, theft, misuse, unauthorized access,
              disclosure, or alteration. These include security of our physical
              premises, and various security safeguard software and firewalls to
              prevent unauthorized access to Your personal information. We do
              not store any credit or debit card information or have direct
              control over such information. Control over such information rests
              with the third-party payment processors engaged by Us.
            </p>
            <p>
              While we endeavour to keep your Personal Information safe, our
              collection, use, and disclosure of your Personal Information is
              not without risks. For example, our security safeguards may be
              compromised by viruses, hacking attempts, or physical break-ins.
              Consequently, we cannot guarantee the security of your Personal
              Information. If your Personal Information was wrongfully accessed,
              there is a risk that you may suffer a harm, such as identity or
              credit card theft or financial loss.
            </p>
            <h6>G. Access and Rectification</h6>
            <p>
              You have a right to challenge the accuracy and completeness of
              your Personal Information and to have it amended or deleted, as
              appropriate. You also have a right to request access to your
              Personal Information and receive an accounting of how that
              information has been used and disclosed, subject to certain
              exceptions prescribed by law. For example, if the requested
              information would reveal Personal Information about another
              individual, your request for access may be limited or denied.{" "}
            </p>
            <p>
              To request access or to amend your Personal Information, please
              write to our Privacy Officer at [NTD – need email address of
              privacy officer] or at the address provided in our Contact
              Information section below.{" "}
            </p>
            <h6>H. Links to Other Sites</h6>
            <p>
              Our Platform may contain links to other sites that are not
              operated by us. If you click on a third-party link, you will be
              directed to that third party's site. We strongly advise you to
              review the privacy policy of every site you visit. We have no
              control over and assume no responsibility for the content, privacy
              policies or practices of any third party sites or services.{" "}
            </p>
            <h6>I. Age Restriction </h6>
            <p>
              We do not knowingly collect or maintain Personal Information from
              people under 19 years old. If we learn that Personal Information
              of people under 19 years old has been collected by us, we will
              take appropriate steps to delete this information unless we are
              legally required to keep it.
            </p>
            <p>
              By using our Platform you confirm that you are at least 19 years
              old. In the event that you are over the age of 19 but under the
              age of majority in your jurisdiction, you must obtain the consent
              of your parents or legal guardian before using our website or
              engaging with our Platform. By using our Platform you and your
              parents or legal guardian confirm that you consent to the
              collection, use, and disclosure of your Personal Information as
              set out in this Privacy Policy.
            </p>
            <h6>J. Contact Information</h6>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              the handling of your Personal Information, if you wish to withdraw
              your consent to our use or disclosure of your Personal
              Information, or to request access to or update any of your
              Personal Information we have on file, please contact our Privacy
              Officer at:{" "}
            </p>
            <p>[NTD – need privacy officer contact here]</p>
            <h6>K. Changes to this Privacy Policy </h6>
            <p>
              We may change this Privacy Policy from time to time. Any changes
              will be posted on our website. The “effective date” notice at the
              top of our Privacy Policy lets you know when this Privacy Policy
              was last updated. Please check from time to time to ensure you are
              aware of our current policy. Any changes to our Privacy Policy are
              effective as of the “effective date”.{" "}
            </p>
            <div className="d-flex">
              <span className="pcHeader"> Effective Date:</span>{" "}
              <p> &nbsp;[February 09, 2024].</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
