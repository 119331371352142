import React, { useState } from "react";
import { Button, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import StripeCardElements from "./StripeCardElements";
import PaymentSuccessFul from "../../../common/modals/PaymentSuccessFul";
import CustomMobileHeader from "../../../common/mobile/CustomMobileHeader";
import Footer from "../../../common/helper/footer/footer";
import ApproveAppointment from "../../../common/helper/modals/Approve";
import "../profile-components/create-profile.scss";
import "../../../styles/form.scss";
import "./payment.scss";

export default function Payments(props) {
  const [visible, setVisible] = useState();
  const [loading, setLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [stripeError, setStripeError] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });

  const stripe = useStripe();
  const elements = useElements();
  const user = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const showPaymentSuccessModal = (visible) => {
    setVisible(visible);
  };

  const [cardData, setCardData] = useState({
    name: "",
  });

  const [checkFields, setCheckFields] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    name: false,
  });

  const handleSubmit = async () => {
    try {
      if (elements == null) return;
      setLoading(true);
      const { token, error } = await stripe.createToken(
        elements.getElement(CardNumberElement)
      );

      if (error) {
        message.error(error.message);
        setLoading(true);
      }
      const payload = {
        token: token?.id,
      };
      const res = await axiosInstance.post("/businessPayment/addCard", payload);
      if (res.data?.status === 200) {
        setLoading(true);
        elements?.getElement(CardNumberElement).clear();
        elements?.getElement(CardExpiryElement).clear();
        elements?.getElement(CardCvcElement).clear();
        const params = { businessId: user._id };

        axiosInstance
          .post("/business/addBusinessToTopList", params)
          .then(async () => {
            showPaymentSuccessModal(true);
            message.success(
              "Selected services added into top listing successfully."
            );
            await axiosInstance.put("/business/updateProfile", { screen: 6 });
            localStorage.setItem("screen", 6);
          })
          .catch((err) => {
            message.error(err.data?.error?.message);
          });
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.error?.message);
    }
  };

  const handleChange = async (event, cardinput) => {
    const e = await event;
    if (cardinput == "name") {
      setCardData({
        ...cardData,
        [e.target.name]: e.target.value,
      });
      if (e.target.value.length > 1) {
        setCheckFields({ ...checkFields, name: true });
      } else {
        setCheckFields({ ...checkFields, name: false });
      }
    } else {
      setCheckFields({ ...checkFields, [e.elementType]: e.complete });
      if (e.elementType === "cardNumber") {
        setStripeError({
          ...stripeError,
          cardNumber: e.error ? e.error.message : "",
        });
      } else if (e.elementType === "cardCvc") {
        setStripeError({
          ...stripeError,
          cardCvc: e.error ? e.error.message : "",
        });
      } else if (e.elementType === "cardExpiry") {
        setStripeError({
          ...stripeError,
          cardExpiry: e.error ? e.error.message : "",
        });
      }
    }
  };

  const onClose = () => {
    setApproveModal(false);
  };

  return (
    <>
      <CustomMobileHeader />
      <div
        className={`container-fluid create-profile-main ${
          props.addnewClass ? "modal-fluid-main" : ""
        }`}
      >
        <div className="container payment-wrapper">
          <div className="row create-profile-heading">
            <div className="col-12 text-center">
              <h1>Payment</h1>
            </div>
          </div>
          <div className="row add-service-list-row add-service-list-row-update">
            <div
              className={`col-12 col-sm-12 col-md-8 mx-auto ${
                props.addnewClass ? "col-md-12" : ""
              }`}
            >
              <div className="row mx-0 common-card-row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 text-center steps-form-heading">
                      <h5>Description</h5>
                    </div>
                  </div>
                  <div className="row amount-details-row pt-0">
                    <div className="col-12">
                      <ul className="list-inline">
                        <li className="list-inline-item text-start">
                          Monthly Fee
                        </li>
                        <li className="list-inline-item text-end">
                          $ {(state?.monthlyFee || 0).toFixed(2)}
                        </li>
                      </ul>
                      <ul className="list-inline">
                        <li className="list-inline-item text-start">
                          Service Fee
                        </li>
                        <li className="list-inline-item text-end">
                          $ {(state?.serviceFee || 0)?.toFixed(2)}
                        </li>
                      </ul>
                      <ul className="list-inline total-amount-ul">
                        <li className="list-inline-item text-start">
                          Total Amount
                        </li>
                        <li className="list-inline-item text-end">
                          ${" "}
                          {((state?.monthlyFee || 0) + (state?.serviceFee || 0))?.toFixed(2)}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center steps-form-heading">
                      <h5>Enter Card Details</h5>
                    </div>
                  </div>
                  <div className="row amount-details-row pt-0">
                    <div className="col-12 common-form-div form-create-profile">
                      <StripeCardElements
                        cardHoldersName={cardData.name}
                        checkFields={checkFields}
                        stripeError={stripeError}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.addnewClass ? null : (
            <div className="steps-action steps-action-custom container">
              <div className="row m-auto justify-content-between">
                <div className="w-50 d-non-mobile  p-0">
                  <Footer from={true} />
                  <div className="d-flex  "></div>
                </div>
                {window.location.pathname == '/services/top-listing/add-payments' ? (
                  <div className="w-auto p-0">
                    <Button
                      className="skip-btn"
                      onClick={async () => {
                        if (user?.isBusinessApproved === 'Approved') {
                          await axiosInstance.put("/business/updateProfile", { screen: 6 });
                          localStorage.setItem("screen", 6);
                          navigate("/business-provider/dashboard")
                        }
                        else setApproveModal(true) 
                      }}
                    >
                      Skip for Now
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <PaymentSuccessFul
        visible={visible}
        onClose={() => showPaymentSuccessModal(false)}
        from={state?.from}
      />
      <ApproveAppointment
        visible={approveModal}
        onClose={onClose}
      />
    </>
  );
}
