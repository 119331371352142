export const columns = [
  {
    title: "Sr. No.",
    dataIndex: "key",
  },
  {
    title: "Business Name",
    dataIndex: "businessName",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Submitted Date",
    dataIndex: "submittedDate",
  },
  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
  },
];
