import { Button, Dropdown } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Notifications from "./notifications/Notifications";

export default function Navbar() {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const fullName =
    JSON.parse(user)?.firstName + " " + JSON.parse(user)?.lastName;
  const businessName = JSON.parse(user)?.businessName;
  const location = useLocation();
  const userImage = JSON.parse(user)?.profileImage;

  // Access the state data from the location object
  const tabName =
    location.state?.tabname ||
    (location.pathname === "/business-provider/dashboard" ? "Dashboard" : "");

  const items = [
    {
      label: (
        <Button
          onClick={() => {
            navigate("/settings?id=1");
          }}
        >
          <span className="icon-card-d">
            <img
              src="/assets/icons/user-icon-primary-noti.svg"
              alt=""
              className="img-fluid"
            />
          </span>
          Edit Profile
        </Button>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          onClick={() => {
            navigate("/settings?id=2");
          }}
        >
          <span className="icon-card-d">
            <img
              src="/assets/icons/category-icon-primary.svg"
              alt=""
              className="img-fluid"
            />
          </span>
          Edit Services
        </Button>
      ),
      key: "1",
    },
    {
      label: (
        <Button
          onClick={() => {
            navigate("/settings?id=3");
          }}
        >
          <span className="icon-card-d">
            <img
              src="/assets/icons/password-icon-primary.svg"
              alt=""
              className="img-fluid"
            />
          </span>
          Change Password
        </Button>
      ),
      key: "2",
    },
    {
      label: (
        <Button
          onClick={() => {
            navigate("/settings?id=4");
          }}
        >
          <span className="icon-card-d">
            <img
              src="/assets/icons/credit-card-icon-primary.svg"
              alt=""
              className="img-fluid"
            />
          </span>
          Update Payment Information
        </Button>
      ),
      key: "3",
    },
    {
      label: (
        <Button
          onClick={() => {
            navigate("/settings?id=5");
          }}
        >
          <span className="icon-card-d">
            <img
              src="/assets/icons/rating-icon.svg"
              alt=""
              className="img-fluid"
            />
          </span>
          Business Ratings
        </Button>
      ),
      key: "4",
    },
    {
      label: (
        <Button
          onClick={() => {
            navigate("/settings?id=6");
          }}
        >
          <span className="icon-card-d">
            <img
              src="/assets/icons/category-icon-primary.svg"
              alt=""
              className="img-fluid"
              style={{ width: "24px", height: "24px" }}
            />
          </span>
          Top Listing
        </Button>
      ),
      key: "5",
    },
  ];

  return (
    <>
      <div className="navbar-custom">
        <div className="row align-items-center justify-content-between h-100">
          <div className="col-sm-12 col-md-4 col-xl-6 col-lg-6">
            <h3 className="mb-0">{tabName}</h3>
            <h3 className="mb-0">
              {window.location.pathname.includes(
                "/business-provider/appointments/appointment-details"
              ) && "Appointments"}
            </h3>

            {window.location.pathname === "/settings" && <h3>{fullName}</h3>}
          </div>
          <div className="col-sm-12 col-md-8 col-xl-6 col-lg-6 text-end">
            <ul className="list-inline mb-0 notifcation-username">
              <li className="list-inline-item">
                <Notifications />
              </li>
              <li className="list-inline-item ">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                  overlayClassName="custom-dropdown-navbar"
                >
                  <Button
                    className="bg-transparent dropdown-btn border-0 p-0 shadow-none bg"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      src={userImage}
                      // profileImage
                      alt="no"
                      className="img-fluid img-profile"
                    />
                    {businessName}
                    <img
                      src="/assets/icons/down-arrow-black.svg"
                      alt=""
                      className="img-fluid img-arrow"
                      style={{ marginRight: "10px" }}
                    />
                  </Button>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
