import { Button, message, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpinWrapper from "../../../common/Loader/SpinWrapper";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import { columns } from "./staticData";

const Businesses = ({ bankDetails, debitCardDetails }) => {
  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (bankDetails || debitCardDetails) fetchData();
  }, [bankDetails, debitCardDetails]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const res = await axiosInstance.get(
        "/business/getTopListHistory?pageLimit=100&page=1"
      );

      if (res.data?.status === 200 && res.data.output?.list?.length > 0) {
        setLoading(false);
        const list = res.data.output?.list?.map((item, index) => {
          return {
            key: index + 1,
            businessName: user?.businessName,
            amount: `$${item.totalFee}`,
            status: item.isTopListed ? "approved" : "pending",
            submittedDate: dayjs(item.topListedDate).format("YYYY-MM-DD"),
            expiryDate: !item.isTopListed
              ? "N/A"
              : dayjs(item.expiryDate).format("YYYY-MM-DD"),
          };
        });
        setRequests(list);
      }
    } catch (error) {
      setLoading(false);
      message.error(error.data?.error?.message);
    } finally {
      setLoading(false);
    }
  };

  const navigateToListing = async () => {
    localStorage.setItem("screen", 5);
    navigate("/services/top-listing");
  };
  return (
    <SpinWrapper loader={loading}>
      <div className="setting-card row overflow-hidden">
        <div className="col-12 common-form-div settings-form-div">
          <div className="btn-container">
            <Button
              type="primary"
              htmlType="submit"
              className="save-button"
              onClick={navigateToListing}
              style={{ width: "fit-content", marginBottom: "15px" }}
              disabled={["pending", "approved"].includes(user?.topListStatus)}
            >
              {user.topListStatus === "pending"
                ? "Top Listing Request Submitted"
                : user?.topListStatus === "approved"
                ? "Top Listing Request Approved"
                : "Add Business to Top Listing"}
            </Button>
          </div>

          <Table
            columns={columns}
            dataSource={requests}
            style={{ minHeight: "100vh" }}
          />
        </div>
      </div>
    </SpinWrapper>
  );
};

export default Businesses;
