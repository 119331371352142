import React, {useRef, useState} from 'react';
import './landing-new.scss'
import '../../styles/form.scss'
import CustomHeader from "../../common/landing/header-footer/CustomHeader";
import {Button} from "antd";
import ContactUsHome from "../../common/landing/ContactUsHome";
import {Link} from "react-router-dom";
import Drawer from "../../common/drawer";
import TermsAndConditionsContent from "../../common/modals/auths/TermsAndConditionsContent";
import PrivacyPolicyContent from "../../common/modals/auths/PrivacyPolicyContent";
import SignUpModal from "../../common/modals/auths/SignUpModal";
import SignInModal from "../../common/modals/auths/SignInModal";

const year = new Date().getFullYear();

const HomeNew = () => {
    const homeRef = useRef(null);
    const contactRef = useRef(null);
    const howRef = useRef(null);
    const aboutRef = useRef(null);
    const [visible, isVisible] = useState();
    const [visiblePrivacyPolicy, isVisiblePrivacyPolicy] = useState();
    const [isSignUpOpen, setIsSignUpOpen] = useState();
    const [isSignInOpen, setIsSignInOpen] = useState();
    const showTermsConditionModal = (visible) => {
        isVisible(visible);
    };
    const showPrivacyPolicyModal = (visible) => {
        isVisiblePrivacyPolicy(visible);
    };
    const showModalSignup = (visible) => {
        setIsSignUpOpen(visible);
    };
    const showModalSignIn = (visible) => {
        setIsSignInOpen(visible);
    };
    return (<>
        <CustomHeader showModalSignIn={showModalSignIn} showModalSignup={showModalSignup} homeRef={homeRef}
                      howRef={howRef} contactRef={contactRef} aboutRef={aboutRef}/>
        <div ref={howRef} className="container-fluid banner-fluid-main">
            <div className="container">
                <div className="row banner-details-row align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 left-side-content">
                        <h1>Fill Your Schedule. Fuel Your Growth.</h1>
                        <p>THE #1 APP FOR BUSINESSES LOOKING TO FILL THEIR SCHEDULES.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 position-relative right-side-img">
                        <img src="/assets/img-new/holding-hand-smart-phone-mockup-img.png" alt=""
                             className="img-fluid main-mockup"/>
                        <ul className="mb-0 list-inline">
                            <li>
                                <button className="btn p-0 border-0 rounded-0">
                                    <img src="/assets/img-new/app-store.png" alt="" className="img-fluid"/>
                                </button>
                            </li>
                            <li>
                                <button className="btn p-0 border-0 rounded-0">
                                    <img src="/assets/img-new/google-play.png" alt="" className="img-fluid"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div ref={aboutRef} className="container-fluid about-us-fluid">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <h2>Say Hello to <br/> Last Minute App</h2>
                        <p>Fed up with last-minute cancellations? Our app connects your business with clients seeking
                            immediate bookings.
                            Set your availability, fill empty slots, and boost revenue—all on your terms. Enjoy a
                            seamless experience that keeps your business running smoothly.</p>
                        <p>Sign up and turn every minute into an opportunity.</p>
                        <Button onClick={() => showModalSignup(true)} className="common-btn signup-btn">Sign Up For
                            Free</Button>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <img src="/assets/img-new/about-right-img.png" alt="" className="img-fluid about-us-img d-none d-sm-block d-sm-block d-sm-block d-sm-block"/>
                        <img src="/assets/img-new/about-us-mobile-img.png" alt="" className="img-fluid about-us-img d-block d-sm-none d-md-none d-lg-none d-xl-none"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid business-owners-fluid">
            <div className="container">
                <div className="row mx-0">
                    <div className="col-12 heading-main col-sm-12 col-md-10 col-lg-6 col-xl-6 mx-auto text-center">
                        <div className="info-heading-tag">
                            For Business Owners
                        </div>
                        <h2>Book <span>Connect</span> Thrive</h2>
                        <p>Effortlessly grow your business with our on-demand platform
                            in three simple steps.</p>
                    </div>
                </div>
                <div className="row mx-0 business-owners-row">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-connect connect-1">
                        <div className="number-value">01</div>
                        <p className="mb-0">List your services and set your availability to match your schedule.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-connect connect-2">
                        <div className="number-value">02</div>
                        <p className="mb-0">Connect with customers actively seeking last-minute appointments in your area.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-connect connect-3">
                        <div className="number-value">03</div>
                        <p className="mb-0">
                            Watch your business thrive as you fill open slots, reduce downtime, and maximize revenue.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div ref={howRef} className="container-fluid feature-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center">
                        <div className="feature-tag">
                            Features
                        </div>
                        <img src="/assets/img-new/feature-img.png" alt="" className="img-fluid"/>
                    </div>
                </div>
                <div className="row featured-details-row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-center">
                        <img src="/assets/img-new/dynamic-scheduling-icon.svg" alt="" className="img-fluid"/>
                        <h6>Dynamic <br/> Scheduling</h6>
                        <div className="card-box-feature text-left">
                            <p className="mb-0">Our intuitive platform lets service providers set flexible working
                                hours, ensuring they
                                can efficiently manage their time and meet customer demands.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-center">
                        <img src="/assets/img-new/seamless-payment-processing-icon.svg" alt="" className="img-fluid"/>
                        <h6>Seamless <br/> Payment Processing</h6>
                        <div className="card-box-feature text-left">
                            <p className="mb-0">Our secure payment system ensures that service providers receive
                                compensation promptly upon completing a job, providing peace of mind and financial
                                stability.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-center">
                        <img src="/assets/img-new/localy-service-requests-icon.svg" alt="" className="img-fluid"/>
                        <h6>Local <br/> Service Requests</h6>
                        <div className="card-box-feature text-left">
                            <p className="mb-0">The platform connects service providers with local customers, maximizing
                                efficiency and minimizing travel. Enjoy a steady flow of nearby service requests for
                                increased business growth.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-center">
                        <img src="/assets/img-new/build-your-reputation-icon.svg" alt="" className="img-fluid"/>
                        <h6>Build <br/>
                            Your Reputation</h6>
                        <div className="card-box-feature text-left">
                            <p className="mb-0">Positive reviews boost credibility, attracting more customers and
                                fostering a thriving service-oriented community. Build your reputation with each
                                service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid crafting-excellence-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center heading-craft-main">
                        <h2>Crafting <span>Excellence</span> Together</h2>
                        <p className="mb-0">Join a vast network of Service Providers</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <img src="/assets/img-new/craft-img.png" alt="" className="img-fluid"/>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <ul className="mb-0 list-inline service-tag">
                            <li>Beauty Services</li>
                            <li>Home Services</li>
                            <li>Automotive Services</li>
                            <li>Health & Wellness Services</li>
                            <li>10+ Business Categories</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid for-suers-fluid">
            <div className="container">
                <div className="row mx-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11 col-xxl-11 mx-auto for-suers-heading text-center">
                        <div className="for-users-tag mx-auto">
                            For Users
                        </div>
                        <h3>What You Need When You Need It</h3>
                        <p className="mb-0">Looking for a last-minute appointment or a quick service?
                            Our app makes it easy. Whether you’re in a rush or just prefer booking on the go, discover
                            available local providers ready to help.</p>
                    </div>
                </div>
                <div className="row mx-0 business-owners-row">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-connect connect-1">
                        <div className="number-value">01</div>
                        <p className="mb-0">Explore Services: Browse a wide range of local services tailored to your needs.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-connect connect-2">
                        <div className="number-value">02</div>
                        <p className="mb-0">Book Instantly: Choose a time that works for you and book in just a few taps.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-connect connect-3">
                        <div className="number-value">03</div>
                        <p className="mb-0">
                            Enjoy Convenience: Get the service you need, when you need it, without the hassle.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid get-started-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 mx-auto text-center">
                        <h3>Ready To Get Started?</h3>
                        <p>Download the app and experience the ease of booking services whenever and wherever you need
                            them!</p>
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <Button>
                                    <img src="/assets/img-new/app-store.png" alt="" className="img-fluid"/>
                                </Button>
                            </li>
                            <li className="list-inline-item">
                                <Button>
                                    <img src="/assets/img-new/google-play.png" alt="" className="img-fluid"/>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid little-bit-about-fluid">
            <div className="container position-relative">
                <div className="layer-top-right common-layer">
                    <img src="/assets/img-new/about-lma-layer.png" alt="" className="img-fluid"/>
                </div>
                <div className="layer-bottom-left common-layer">
                    <img src="/assets/img-new/about-lma-layer.png" alt="" className="img-fluid"/>
                </div>
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <img src="/assets/img-new/little-bit-about-img.png" alt=""
                             className="img-fluid d-none d-sm-block d-md-block d-lg-block d-xl-block"/>
                        <img src="/assets/img-new/little-about-lma-mobile.png" alt=""
                             className="img-fluid d-block d-sm-none d-md-none d-lg-none d-xl-none"/>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 padding-left">
                        <h4>A little about LMA</h4>
                        <p>At Last Minute App, passion fuels our journey. We are a collective force, dedicated to
                            crafting exceptional experiences. Rooted in innovation and collaboration, our team strives
                            to redefine industry standards, creating a platform where service seekers and providers
                            converge for unparalleled excellence.</p>
                        {/*<Button className="common-btn signup-btn">Explore More</Button>*/}
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid footer-common">
            <div className="container">
                <div className="row mx-0 contact-us-details-main">
                    <div className="col-12">
                        <ContactUsHome navRef={contactRef}/>
                    </div>
                </div>
                <div className="row mx-0 footer-second-details">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <Link to="/"><img src="/assets/img-new/lma-log-footer-white.svg" alt=""
                                          className="img-fluid"/></Link>
                    </div>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <ul className="list-inline mb-0 footer-nav-link">
                            <li>
                                <Link to="#"
                                      onClick={() => aboutRef?.current?.scrollIntoView({behaviour: "smooth"})}>About
                                    us</Link>
                            </li>
                            <li>
                                <Link onClick={() => howRef?.current?.scrollIntoView({behaviour: "smooth"})}
                                      to="#">How it
                                    works</Link>
                            </li>
                            <li>
                                <Link onClick={() => contactRef?.current?.scrollIntoView({behaviour: "smooth"})}
                                      to="#">Contact
                                    us</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <ul className="list-inline mb-0 footer-nav-link">
                            <li>
                                <Link onClick={() => showTermsConditionModal(true)} to={"javascript:void(0)"}>Terms &
                                    Conditions </Link>
                            </li>
                            <li>
                                <Link onClick={() => showPrivacyPolicyModal(true)} to={"javascript:void(0)"}>Privacy
                                    Policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div
                        className="col-12 col-sm-6 col-md-12 col-lg-3 col-xl-3 text-start text-sm-center text-md-center text-lg-center text-xl-center">
                        <ul className="list-inline mb-0 footer-social-link">
                            <li className="list-inline-item">
                                <Link to={" "}>
                                    <img src="/assets/img-new/facebook-icon.svg" alt="" className="img-fluid"/>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to={" "}>
                                    <img src="/assets/img-new/insta-icon.svg" alt="" className="img-fluid"/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div
                        className="col-12 text-start text-sm-center text-md-center text-lg-center text-xl-center footer-bottom-details">
                        <p className="mb-0">Copyright © {year} Last Minute App</p>
                    </div>
                </div>
            </div>
        </div>
        <Drawer show={visible} onClose={() => showTermsConditionModal(false)}>
            <TermsAndConditionsContent slider/>
        </Drawer>
        <Drawer
            show={visiblePrivacyPolicy}
            onClose={() => showPrivacyPolicyModal(false)}
        >
            <PrivacyPolicyContent slider/>
        </Drawer>
        <SignUpModal
            visible={isSignUpOpen}
            onClose={() => showModalSignup(false)}
            openSignInModal={() => showModalSignIn(true)}
        />
        <SignInModal
            visible={isSignInOpen}
            onClose={() => showModalSignIn(false)}
            openSignUpModal={() => showModalSignup(true)}
            formRef={SignInModal}
        />
    </>);
};

export default HomeNew;