import React from "react";
import { Button, Modal } from "antd";
import "./modals.scss";

export default function DeclineAppointmentModal(props) {
  const { id } = props;

  return (
    <Modal
      centered
      width={576}
      header={null}
      footer={null}
      open={props.visible}
      onCancel={props.onClose}
      onOk={props.onClose}
      className="common-modal decline-modal settings-service-modal"
      closable
      closeIcon={<img alt={""} src={"/assets/icons/close-icon.svg"} />}
    >
      <div className="row">
        <div className="col-12 crossImage common-div-modal text-center">
          <img alt={""} src={"/assets/icons/decline-info-icon.svg"} />
          <h3>{props.headingText}</h3>
          <ul className="mb-0 list-inline">
            <li>
              <Button className="no-gradient button-common" onClick={props.onClose}>
                No
              </Button>
            </li>
            <li>
              <Button
                style={{ marginLeft: "3px" }}
                className="bg-transparent p-0 shadow-none confirm-logout-btn"
                onClick={(e) => {
                  props.acceptorDeclineHandler(e, id, false);
                  props.onClose(e);
                }}
              >
                Yes
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}
