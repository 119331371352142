import { Button, Col, Row, message } from "antd";
import Layout from "../../../common/helper/layout";
import DeclineAppointmentModal from "../../../common/helper/modals/DeclineAppointmentModal";
import { useEffect, useState } from "react";
import CloseAppointmentModal from "../../../common/helper/modals/CloseAppointmentModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import dayjs from "dayjs";
import SpinWrapper from "../../../common/Loader/SpinWrapper";
export default function ProviderAppointmentDetails(props) {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [closemodlvisible, setclosemdlvisible] = useState(false);
  const [avalabilityDetails, setAvalabilityDetails] = useState([]);
  // the services he selected
  const [serviceData, setServicedata] = useState([]);
  const [clsmdldata, setclsmdldat] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const tab = location.state.tabName;

  const showDeclineAppointmentModal = (visible) => {
    setVisible(visible);
  };

  const showclosemodal = (closemodlvisible, index, services) => {
    setServicedata(services);
    setclosemdlvisible(closemodlvisible);
  };

  let { id } = useParams();
  const appointmentDetails = () => {
    setLoader(true);
    axiosInstance
      .get(`/business/appointmentDetails/${id}`)
      .then((res) => {
        setLoader(true);
        setAvalabilityDetails(res?.data?.output);
        setclsmdldat(res?.data?.output[0]);
        setLoader(false);
      })
      .catch((Err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoader(true);
    }, 300);
    appointmentDetails();
  }, []);

  // both accept and decline
  const acceptorDeclineHandler = async (
    e,
    id,
    isapproved,
    startAppointment,
    closeAppointment
  ) => {
    const payload = {
      appointmentId: id,
      isApproved: isapproved,
      //  for acccepting true for decline as false
      startAppointment: startAppointment,
      // start appointment true fro start oppoinment
      closeAppointment: closeAppointment,
    };
    try {
      setTimeout(() => {
        setLoader(true);
      }, 300)
      await axiosInstance
        .post("/business/changeAppointmentStatus", payload)
        .then((res) => {
          appointmentDetails();
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      message.error(error?.data?.error?.message);
    }
  };
  function formatISO8601Time(iso8601String) {
    // Parse the ISO 8601 string into a Date object
    const time = iso8601String.split("T")[1]?.split(":");

    // Get the local time components
    const hour = time[0];
    const minute = time[1];

    // Determine if it's AM or PM
    const period = hour >= 12 ? "PM" : "AM";

    // Convert the hour to 12-hour format
    const hour12 = hour % 12 || 12; // Ensure 12:00 PM instead of 0:00 PM

    // Format the time as "9:00 AM" or "9:00 PM"
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }


  return (
    <>
      {loader && (
        <div style={{ marginTop: "20vh", marginLeft: "20vw" }}>
          <SpinWrapper
            loader={true}
          >
            <Layout></Layout>
          </SpinWrapper>
        </div>
      )}
      {avalabilityDetails.length > 0 &&
        avalabilityDetails.map((avlbl, index) => {
          return (
            <>
              <Layout>
                <SpinWrapper loader={loader}>
                  <div className="appointment-details-container">
                    <h5 className="d-flex details-header">
                      <h5
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(
                            "/business-provider/appointments-dashboard",
                            {
                              state: { statusTab: tab },
                            }
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Appointments |{" "}
                      </h5>
                      <span className="px-1"></span>
                      <span>
                        {`   ${" "}   ${avlbl?.userDetails?.firstName}    ${avlbl?.userDetails?.lastName
                          }`}
                      </span>
                    </h5>
                    <div className="appointment-details-wrapper">
                      <Row>
                        <Col xs={16} md={12} xl={12} lg={12}>
                          <h3 className="person-name mb-5">
                            {avlbl?.userDetails?.firstName}{" "}
                            {avlbl?.userDetails?.lastName}{" "}
                          </h3>
                        </Col>

                        <Col
                          xs={24}
                          md={12}
                          lg={12}
                          xl={12}
                          className={
                            avlbl?.status === "Canceled"
                              ? "d-flex justify-content-end status-tag-col-canceled"
                              : avlbl?.status === "Ongoing"
                                ? "d-flex justify-content-end status-tag-col-ongoing"
                                : avlbl?.status === "Pending"
                                  ? "d-flex justify-content-end status-tag-col-pending"
                                  : "d-flex justify-content-end status-tag-col"
                          }
                        >
                          {avlbl?.status}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} sm={12} xs={24} lg={8} xl={8}>
                          <p className="card-title">Service Fee</p>
                          <p className="card-value mt-2">
                            ${avlbl?.serviceFee}.00
                          </p>
                        </Col>
                        <Col md={12} sm={12} xs={24} lg={8} xl={8} className="d-flex flex-column">
                          <p className="card-title">Address</p>
                          <div className="address-wrapper d-flex align-items-center mt-2">
                            <img
                              alt={""}
                              src="/assets/icons/location.svg"
                              width={20}
                            />
                            <p className="card-value ml-2">
                              {avlbl?.userDetails?.address}{" "}
                              {avlbl?.userDetails?.city}{" "}
                              {avlbl?.userDetails?.province}{" "}
                              {avlbl?.userDetails?.postalCode}
                            </p>
                          </div>
                        </Col>
                        <Col md={12} sm={12} xs={24} lg={8} xl={8} className="d-flex flex-column">
                          <h6 className="card-title">Date and Time</h6>
                          <div className="date-time-wrapper d-flex mt-2">
                            <div className="date-wrapper d-flex align-items-center">
                              <img
                                className="img-fluid"
                                alt={""}
                                src="/assets/icons/calendar.svg"
                              />
                              <h5 className="card-value">
                                {dayjs(avlbl?.appointmentDate).format(
                                  "MMMM D, YYYY"
                                )}
                              </h5>
                            </div>
                            <div className="time-wrapper d-flex align-items-center">
                              <img
                                className="img-fluid"
                                alt={""}
                                src="/assets/icons/clock.svg"
                              />
                              <h5 className="card-value">
                                {formatISO8601Time(avlbl?.appointmentDate)}
                              </h5>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} sm={12} xs={24} lg={8} xl={8}>
                          <p className="card-title">Service Category</p>
                          <p className="card-value mt-2">
                            {avlbl?.selectedServices[0]?.categoryName}
                          </p>
                        </Col>
                        <Col md={12} sm={12} xs={24} lg={16} xl={16} className="d-flex flex-column">
                          <h6 className="card-title">Services</h6>
                          <div
                            className="d-flex gap-3 mt-2"
                            style={{ flexWrap: "wrap" }}
                          >
                            {avlbl?.selectedServices
                              ?.reverse()
                              .map((services) => {
                                return (
                                  <>
                                    <ul className="services-wrapper list-inline mb-0 flex-row">
                                      <li className="list-inline-item service-wrapper__child">
                                        <div className="d-flex align-items-center">
                                          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                          <img
                                            className="img-fluid-services"
                                            src={services?.imgUrl}
                                            alt="service-image"
                                          />
                                          <h5 className="card-value mb-0 ms-2">
                                            {services?.title}
                                          </h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} sm={12} xs={24} lg={8} xl={8}>
                          <p className="card-title">Phone Number</p>
                          <p className="card-value mt-2">
                            {avlbl?.userDetails?.countryCode}{" "}
                            {(avlbl?.userDetails?.phoneNumber)?.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "$1 $2 $3"
                            )}
                          </p>
                        </Col>
                        <Col md={12} sm={12} xs={24} lg={8} xl={8}>
                          <p className="card-title">Email Address</p>
                          <p className="card-value mt-2">
                            {avlbl?.userDetails?.email}{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="card-title">Additional Notes</p>
                          <p className="additional-info mt-2">
                            {avlbl?.notes ? avlbl?.notes : "N/A"}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-0 bottom-appointment-btn-row p-none">
                        {avalabilityDetails[0]?.status == "Ongoing" && (
                          <Col md={24}>
                            <ul className="list-inline text-end">
                              <li className="list-inline-item">
                                <Button
                                  onClick={() => showclosemodal(true)}
                                  className="common-secondary-btn"
                                >
                                  Close Appointment
                                </Button>
                              </li>
                              <li className="list-inline-item">
                                <Button className="common-secondary-btn pe-none">
                                  Started
                                </Button>
                                {/* )} */}
                              </li>
                            </ul>
                          </Col>
                        )}

                        {/* test2 */}
                        {avalabilityDetails[0]?.status == "Upcoming" && (
                          <Col md={24}>
                            <ul className="list-inline  text-end">
                              <li className="list-inline-item">
                                <Button
                                  onClick={() =>
                                    showDeclineAppointmentModal(true)
                                  }
                                  className="common-secondary-btn"
                                >
                                  Decline
                                </Button>
                              </li>
                              <li className="list-inline-item">
                                <Button
                                  className="common-btn"
                                  onClick={(e) => {
                                    acceptorDeclineHandler(
                                      e,
                                      id,
                                      undefined,
                                      true
                                    );
                                    setVisible(false);
                                  }}
                                >
                                  Start Appointment
                                </Button>
                                {/* )} */}
                              </li>
                            </ul>
                          </Col>
                        )}

                        {/* test3 */}
                        {avalabilityDetails[0]?.status == "Pending" && (
                          <Col md={24}>
                            <ul className="list-inline  text-end">
                              <li className="list-inline-item">
                                <Button
                                  onClick={() =>
                                    showDeclineAppointmentModal(true)
                                  }
                                  className="common-secondary-btn"
                                >
                                  Decline
                                </Button>
                              </li>
                              <li className="list-inline-item">
                                <Button
                                  className="common-btn"
                                  onClick={(e) => {
                                    acceptorDeclineHandler(e, avlbl._id, true);
                                  }}
                                >
                                  Accept
                                </Button>
                                {/* )} */}
                              </li>
                            </ul>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </SpinWrapper>
              </Layout>
            </>
          );
        })}
      {visible ? (
        <DeclineAppointmentModal
          headingText={"Are you sure you want to decline this appointment?"}
          visible={visible}
          onClose={() => showDeclineAppointmentModal(false)}
          id={avalabilityDetails[0]._id}
          acceptorDeclineHandler={acceptorDeclineHandler}
        />
      ) : (
        ""
      )}
      {closemodlvisible && (
        <CloseAppointmentModal
          closemodlvisible={closemodlvisible}
          onClose={() => showclosemodal(false)}
          id={id}
          acceptorDeclineHandler={acceptorDeclineHandler}
          avalabilityDetails={avalabilityDetails}
          serviceData={serviceData}
          clsmdldata={clsmdldata}
        />
      )}
    </>
  );
}
